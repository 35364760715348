<template>
  <tr class="TopClippingsRow is-cursor-pointer" @click="openClippingPreview(clipping)">
    <td class="has-text-centered is-narrow is-size-2">
      <div class="is-width-16 has-text-weight-bold">
        {{ index }}
      </div>
    </td>
    <td class="TopClippingsRow__image is-narrow">
      <div v-if="clipping.thumbnail.length" class="image is-4by3">
        <BackgroundImage :src="image" class="floated" />
      </div>
    </td>
    <td class="has-text-left TopClippingsRow__media-data is-width-2/5 border-r-white">
      <div class="has-text-weight-bold m-b-xs">
        {{ brandName }}
      </div>
      <div class="m-b-xs TopClippingsRow__media-headline">
        {{ truncate(clipping.data.headline, 70) }}
      </div>
      <div class="has-text-primary">
        {{ clipping.media_outlet.data.name }}
      </div>
    </td>
    <td class="TopClippingsRow__media-meta">
      <table class="TopClippingsRow__media-meta__table">
        <tr>
          <td>{{ $t('general.impressions', locale) }}</td>
          <td class="has-text-weight-bold">
            <template v-if="clipping.impressions">
              {{ formatImpressionsValue(clipping.impressions) }}
            </template>
            <template v-else>
              {{ $t('general.not_available', locale) }}
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t('general.hf_value', locale) }}</td>
          <td class="has-text-weight-bold">
            <template v-if="hfValue">
              {{ formatMoney(hfValue) }}
            </template>
            <template v-else>
              {{ $t('general.not_available', locale) }}
            </template>
          </td>
        </tr>
        <tr>
          <td>{{ $t('general.date', locale) }}</td>
          <td class="has-text-weight-bold">
            {{ datetime(clipping.published_at, 'DD.MM.YYYY') }}
          </td>
        </tr>
      </table>
    </td>
    <td class="is-narrow">
      <div class="m-b-s">
        <div
          :title="$t(mediaTypes[clipping.type].label, locale)"
          :style="{ backgroundColor: mediaTypes[clipping.type].color }"
          class="icon is-medium is-round p-a-s"
        >
          <ClippingTypeIcon :type="clipping.type" fallback />
        </div>
      </div>
      <div v-if="tier">
        <Tier :tier="tier" class="is-inline-block" size="medium" />
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

import { ImageResizerService } from '../../services/ImageResizerService'

import BackgroundImage from '@hypefactors/shared/js/components/core/BackgroundImage'
import ClippingTypeIcon from '@hypefactors/shared/js/components/clippings/ClippingTypeIcon'
import Tier from '@hypefactors/shared/js/components/clippings/Tier'

import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'

/**
 * A row from the {@see module:TopClippings} table
 * @module TopClippingsRow
 */
export default {
  name: 'TopClippingsRow',

  components: {
    ClippingTypeIcon,
    BackgroundImage,
    Tier
  },

  mixins: [TruncateMixin, FormatMoneyMixin, DatetimeMixin, FormatImpressionsValueMixin],

  props: {
    index: {
      type: Number,
      default: 0
    },
    clipping: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    ...mapGetters(['activeBrand']),

    clippingBrandId () {
      return this.$safeGet(this.clipping, 'brand.data.id') || this.activeBrand.id
    },

    brandName () {
      return this.$safeGet(this.clipping, 'brand.data.name', '') || this.activeBrand.name // fallback for reports
    },

    hfValue () {
      return this.$safeGet(this.clipping, 'hf_value', 0)
    },

    tier () {
      return this.$safeGet(this.clipping, 'tier', 'no-tier')
    },

    mediaTypes () {
      return MEDIA_TYPES
    },

    image () {
      return ImageResizerService.resize(this.clipping.thumbnail[0], 87, 65, { quality: 30 })
    }
  },

  methods: {
    openClippingPreview (clipping) {
      this.$bus.$emit('ClippingPreviewModal:open', clipping)
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.TopClippingsRow {
  border-bottom: 3px solid $white;
  line-height: 1.1;

  td {
    padding: $padding-small !important;
  }

  &__image {
    min-width: 100px;
  }

  &__media-outlet {
    width: 15%;
  }

  &__media-meta__table {
    border: none;

    td {
      padding: $padding-tiny $padding-small !important;
      text-align: left !important;
      border: none;
    }

    td:first-of-type {
      letter-spacing: 0.07em;
      font-weight: $hf__font-weight-bold;
    }
  }
}
</style>
