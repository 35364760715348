import { buildUrlWithParams } from '../utils/UrlUtils'

const RESIZER_URL = process.env.VUE_APP_IMAGE_RESIZER_URL

export const ImageResizerService = {
  _buildUrl (width, height, params) {
    const defaultParams = {
      quality: 100
    }
    Object.assign(defaultParams, params)
    // build the full url `url.com/width/?height
    const baseUrl = [
      RESIZER_URL, width, height
    ].filter(Boolean).join('/')

    return { defaultParams, baseUrl }
  },
  /**
   * Returns the proper resize URL
   * For more info on params, check https://github.com/pmb0/express-sharp
   * @param {String} imageUrl
   * @param {Number} width
   * @param {Number} [height]
   * @param {Object} [params]
   * @return {String}
   */
  resize (imageUrl, width, height, params) {
    const { baseUrl, defaultParams } = ImageResizerService._buildUrl(width, height, params)
    return buildUrlWithParams(baseUrl, {
      ...defaultParams,
      // remove the https part, as its handled on the cloud function
      url: imageUrl
    })
  },
  /**
   * Returns the proper resize and crop URL
   * For more info on params, check https://github.com/pmb0/express-sharp
   * @param {String} imageUrl
   * @param {Number} width
   * @param {Number} [height]
   * @param {Object} [params]
   * @return {String}
   */
  crop (imageUrl, width, height, params) {
    const { baseUrl, defaultParams } = ImageResizerService._buildUrl(width, height, params)
    return buildUrlWithParams(baseUrl, {
      ...defaultParams,
      crop: true,
      // remove the https part, as its handled on the cloud function
      url: imageUrl
    })
  }
}
