<template>
  <div class="TopClippings">
    <ResponsiveTable v-if="clippings.length">
      <table class="table is-full has-cells-centered has-cells-vertically-centered has-no-borders has-background-grey-lighter">
        <tbody>
          <TopClippingsRow
            v-for="(clipping, index) in clippings"
            :key="clipping.id"
            :index="index+1"
            :clipping="clipping"
            :locale="locale"
          />
        </tbody>
      </table>
    </ResponsiveTable>
    <div v-else>
      {{ $t('components.top_clippings.none_found', locale) }}
    </div>
  </div>
</template>

<script>
import ResponsiveTable from './ResponsiveTable'
import TopClippingsRow from './TopClippingsRow'

/**
 * Renders a table of clippings
 * @module TopClippings
 */
export default {
  components: {
    ResponsiveTable,
    TopClippingsRow
  },
  props: {
    clippings: {
      type: Array,
      default: () => ([])
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
